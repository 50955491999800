import React from 'react';
import dynamic from 'next/dynamic';
import CircleSpinner from '../components/LoadingAnimation/CircleSpinner';
import { serverLocationDetector } from '../utils/locationDetector';
import Head from 'next/head';
const Layout = dynamic(() => import('../components/_App/Layout'), {
    loading: () => <CircleSpinner fullScreen={true} />,
})
const Home = dynamic(() => import('../templates/Home'))

const Index = (props) => {
    return (<>
        <Layout
            showLoader={true}
            isAppleMobile = {props.isAppleMobile}
            seo={{
                description: "Weedzly is your trusted resource for everything cannabis. Find deals and discounts and start saving money at the dispensary.",
                image:'/static/icons/favicon.png',
                title: 'Weedzly - Find Cannabis Deals & Discounts Near You',
                url:'https://weedzly.com/'
            }}
        >
            <Head>
                <script type="application/ld+json">
                {
                    JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "Corporation",
                        "name": "Weedzly",
                        "alternateName": "Weedzly",
                        "url": "https://weedzly.com/",
                        "logo": "https://weedzly.com/_next/static/media/logo-small.4740a214.png",
                        "sameAs": [
                            "https://www.facebook.com/OfficialWeedzly/",
                            "https://twitter.com/weedzlyofficial",
                            "https://www.instagram.com/weedzlyofficial/"
                        ]
                    })
                }
                </script>
            </Head>
            <Home {...props} />
        </Layout></>
    );
}

export default Index;

export async function getServerSideProps(context){
    let isAppleMobile = (context.req
        ? context.req.headers['user-agent']
        : navigator.userAgent).match(
          /iPhone|iPad|iPod/i
        )
    await serverLocationDetector(context)
    return {
        props: {
            geo: context.query,
            isAppleMobile
        }
    } 
}