import React from 'react';
import { css } from "@emotion/core";
import Spinner from 'react-spinner-material';
import Image from 'next/image';
import SVG from "react-inlinesvg";

const override = css`
    display: flex;
    margin: 0 auto;
    border-color: red;
    align-items: center;
    justify-content: center;
`;

const overrideFullScreen = css`
    display: block;
    border-color: red;
`;

const fullScreenStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    flexDirection: 'column',
}

const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
}

const CircleSpinner = (props) => {
    return (
        <div style={props.fullScreen ? fullScreenStyle : props.style ? props.style : style } className={`circle-loading ${props.className}`}>
            { props.fullScreen ? <SVG width={200} src={'/static/images/logo-letters.svg'}></SVG> : "" }<br/>
            <div>
                <Spinner radius={props.radius ? props.radius : 70} color={"#6c3478"} stroke={8} visible={true} />
            </div> 
        </div>
    )
}

export default CircleSpinner;